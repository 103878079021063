/* stylelint-disable selector-type-no-unknown */

import styled, { css } from 'styled-components';

import * as vr from 'core/styles/variables';

import { baseInputStyles } from 'pods/forms/components/inputs/Text/Text.style';

export const SELECT_INPUT_CLASS = 'formBuilder__select';

export const SelectContainer = styled.div<{
	isInline: boolean;
	width?: number;
	hasError: boolean;
	selectedOption: any;
}>`
	${`.${SELECT_INPUT_CLASS}`} {
		${props => baseInputStyles(props)}
		padding: 0;
		border: none;
		cursor: pointer;

		${({ isInline }) =>
			isInline &&
			css`
				display: inline-block;
				width: auto;
			`}

		${({ width }) =>
			width &&
			css`
				width: ${width}px;
			`}

		.${SELECT_INPUT_CLASS}__indicator-separator {
			display: none;
		}

		.${SELECT_INPUT_CLASS}__value-container {
			padding: 0;

			& > .${SELECT_INPUT_CLASS}__single-value {
				${({ hasError }) =>
					hasError &&
					css`
						color: ${vr.formfieldErrorColor};
					`}
			}
		}

		.${SELECT_INPUT_CLASS}__dropdown-indicator {
			color: ${vr.formSelectIndicatorColor};
			padding: 0;

			${({ hasError }) =>
				hasError &&
				css`
					color: ${vr.formfieldErrorColor};
				`}
		}

		.${SELECT_INPUT_CLASS}__control {
			padding: 12px 16px;
			transition: none !important;

			&--is-focused {
				border: 1px solid
					${({ hasError }) =>
						hasError
							? vr.formfieldErrorColor
							: vr.formfieldBorderColorFocus} !important;
				box-shadow: 0 0 0 1px
					${({ hasError }) =>
						hasError
							? vr.formfieldErrorColor
							: vr.formfieldBorderColorFocus} !important;
				padding: 12px 16px;

				.${SELECT_INPUT_CLASS}__indicators {
					color: ${vr.formfieldBorderColorFocus};
				}
			}

			${({ hasError }) =>
				hasError &&
				css`
					border: 1px solid ${vr.formfieldErrorColor} !important;
					box-shadow: 0 0 0 1px ${vr.formfieldErrorColor};
					padding: 12px 16px;
				`}

			&:hover {
				border: 1px solid
					${({ hasError }) =>
						hasError ? vr.formfieldErrorColor : vr.formfieldOnHoverBorderColor};
				box-shadow: 0 0 0 1px
					${({ hasError }) =>
						hasError ? vr.formfieldErrorColor : vr.formfieldOnHoverBorderColor};
			}
		}

		.${SELECT_INPUT_CLASS}__placeholder {
			color: ${vr.formfieldPlaceholderColor}

			${({ hasError }) =>
				hasError &&
				css`
					color: ${vr.formfieldErrorColor} !important;
				`}
		}

		.${SELECT_INPUT_CLASS}__control--menu-is-open {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		.${SELECT_INPUT_CLASS}__menu {
			margin: 0;
			border-radius: 0;
			box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.18);
		}

		.${SELECT_INPUT_CLASS}__option {
			padding: 12px 16px;
			position: relative;
			color: ${({ theme }) => theme.global.form.select.option.base};

			&:first-child {
				margin-top: -4px;
			}

			&:last-child {
				margin-bottom: -4px;
			}

			&--is-selected {
				background-color: ${vr.brandWhite};
				color: ${({ theme }) => theme.global.form.select.option.base};

				&::before {
					content: '';
					position: absolute;
					width: 4px;
					height: 100%;
					top: 0;
					left: 0;
					background-color: ${({ theme }) => theme.global.form.select.active};
				}
			}

			&--is-focused {
				background-color: ${({ theme }) => theme.global.form.select.hover};
				color: ${({ theme }) => theme.global.form.select.option.hover};
				cursor: pointer;

				&::before {
					content: '';
					position: absolute;
					width: 4px;
					height: 100%;
					top: 0;
					left: 0;
					background-color: ${({ theme }) => theme.global.form.select.active};
				}
			}
		}

		&--is-disabled {
			.${SELECT_INPUT_CLASS}__indicators {
				color: ${vr.radioCheckboxDisabled};
			}


		}
	}
`;

/**
 * @note GlobalSelectPortal is used inside GlobalStyles.js
 * since the portal is appended to the body
 *
 * CSS below is the same inside SelectContainer, the difference is that
 * it is wrapped in the body tag
 */

export const GlobalSelectPortal = css<{
	theme: any;
}>`
	body {
		.${SELECT_INPUT_CLASS}__menu {
			margin: 0;
			border-radius: 0;
			box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.18);
		}

		.${SELECT_INPUT_CLASS}__option {
			padding: 12px 16px;
			position: relative;
			color: ${({ theme }) => theme.global.form.select.option.base};

			&:first-child {
				margin-top: -4px;
			}

			&:last-child {
				margin-bottom: -4px;
			}

			&--is-selected {
				background-color: ${vr.brandWhite};
				color: ${({ theme }) => theme.global.form.select.option.base};

				&::before {
					content: '';
					position: absolute;
					width: 4px;
					height: 100%;
					top: 0;
					left: 0;
					background-color: ${({ theme }) => theme.global.form.select.active};
				}
			}

			&--is-focused {
				background-color: ${({ theme }) => theme.global.form.select.hover};
				color: ${({ theme }) => theme.global.form.select.option.hover};
				cursor: pointer;

				&::before {
					content: '';
					position: absolute;
					width: 4px;
					height: 100%;
					top: 0;
					left: 0;
					background-color: ${({ theme }) => theme.global.form.select.active};
				}
			}

			&--is-disabled {
				display: inline-flex;
				justify-content: space-between;
				background-color: ${({ theme }) => theme.global.page.color};

				&::after {
					content: 'Restricted';
					display: flex;
					justify-content: flex-end;
				}
			}
		}
	}
`;

export const LoadingWrapper = styled.div`
	align-items: center;
	display: flex;
	padding: 3px;
`;

export const CustomOptionSubLabel = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	p:nth-child(1) {
		margin: 0 0 4px 0;
	}

	p:nth-child(2) {
		margin: 0;
		font-size: 12px;
		color: ${({ theme }) => theme.global.typography.colors.subdued};
	}
`;
