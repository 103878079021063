/* eslint-disable @typescript-eslint/camelcase */

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import config from 'jss-boilerplate/temp/config.js';
import { getCurrentUrlHostname } from 'core/utils/global-utils';
import {
	AKAMAI_BOT_FLAG,
	SITECORE_AUTHORIZATION,
	X_CORRELATION_APP,
} from 'core/constants';
import {
	ILoginPasswordRequestData,
	IMobileAuthRequestData,
	IMobileAuthPushInitiateRequestData,
	IMobileAuthPushDeviceCheckRequestData,
	IMobileAuthPushPollingWaitData,
} from 'jss-components/t07-account-access/Login/Login.types';

const isDevelopmentEnv = process.env.NODE_ENV === 'development';

/**
 * Call Sitecore API for Member Login
 * @param {any} data Username and Password
 */
export function memberLogin(data: any) {
	// TODO - define types

	const url = `${getCurrentUrlHostname}${config.sitecoreApi.apiPathMemberLogin}`;

	return axios({
		url,
		method: 'POST',
		data,
		// note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
		// which is necessary for analytics and such, same with lines 69 and 84
		withCredentials: !isDevelopmentEnv,
	});
}

/**
 * MAU Journey 2: Login Pwd Auth for Token ID
 * Second call for password login
 * @param {any} data Username, Password, initial, usernamenew
 */
export function memberLoginClassic(data: ILoginPasswordRequestData) {
	const url = `${getCurrentUrlHostname}${config.sitecoreApi.apiPathMemberLoginClassic}`;

	return axios({
		url,
		method: 'POST',
		data,
		// note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
		// which is necessary for analytics and such, same with lines 69 and 84
		withCredentials: !isDevelopmentEnv,
	});
}

/**
 *
 * @param data Username, device details: containing uuid(), window.navigator, lat/lng
 */
export function memberDeviceLogin(data: IMobileAuthRequestData) {
	const url = `${getCurrentUrlHostname}${config.sitecoreApi.apiPathMemberDeviceLogin}`;

	return axios({
		url,
		method: 'POST',
		data,
		// note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
		// which is necessary for analytics and such, same with lines 69 and 84
		withCredentials: !isDevelopmentEnv,
	});
}

/**
 *
 * @param data Username
 *
 * 1st MAU push call, account existence check
 */
export function memberPushInitiate(data: IMobileAuthPushInitiateRequestData) {
	const url = `${getCurrentUrlHostname}${config.sitecoreApi.apiPathMemberPushInitiate}`;

	return axios({
		url,
		method: 'POST',
		data,
		// note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
		// which is necessary for analytics and such, same with lines 69 and 84
		withCredentials: !isDevelopmentEnv,
	});
}

/**
 *
 * @param data
 *
 * 2nd MAU push call, account locked check
 */
export function memberPushAccountCheck(data: {}) {
	const url = `${getCurrentUrlHostname}${config.sitecoreApi.apiPathMemberPushAccountCheck}`;

	return axios({
		url,
		method: 'POST',
		data,
		// note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
		// which is necessary for analytics and such, same with lines 69 and 84
		withCredentials: !isDevelopmentEnv,
	});
}

/**
 *
 * @param data
 *
 * 3rd MAU push call, check if device is registered
 */
export function memberPushDeviceCheck(
	data: IMobileAuthPushDeviceCheckRequestData,
) {
	const url = `${getCurrentUrlHostname}${config.sitecoreApi.apiPathMemberPushDeviceCheck}`;

	return axios({
		url,
		method: 'POST',
		data,
		// note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
		// which is necessary for analytics and such, same with lines 69 and 84
		withCredentials: !isDevelopmentEnv,
	});
}

/**
 *
 * @param data IMobileAuthPushPollingWaitData
 *
 * Actual polling wait call. Loops according to set total duration and intervals
 * Will be waiting for either accept/deny/expired responses
 */
export function memberPushPollingWait(data: IMobileAuthPushPollingWaitData) {
	const url = `${getCurrentUrlHostname}${config.sitecoreApi.apiPathMemberPushPollingWait}`;

	return axios({
		url,
		method: 'POST',
		data,
		// note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
		// which is necessary for analytics and such, same with lines 69 and 84
		withCredentials: !isDevelopmentEnv,
	});
}

/**
 *
 * @param data
 *
 * Absolutely redundant endpoint for resending or canceling while within
 * expired/denied page
 */
export function memberPushResend(data: IMobileAuthPushDeviceCheckRequestData) {
	const url = `${getCurrentUrlHostname}${config.sitecoreApi.apiPathMemberPushResend}`;

	return axios({
		url,
		method: 'POST',
		data,
		// note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
		// which is necessary for analytics and such, same with lines 69 and 84
		withCredentials: !isDevelopmentEnv,
	});
}

/**
 * Call CAT Authorise API for Member Login
 */
export function memberAuthorize(ssoTokenId: string, redirectUrl: string) {
	const base = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathIdentity}`;

	const url = `${base}/sitecore-authorize`;

	return axios({
		url,
		method: 'POST',
		headers: {
			'X-API-Key': config?.externalServices?.coarseGrainApiKey, // API Coarse grain
			'X-Correlation-Id': uuidv4(), // FED initiated correlationID
			'X-Correlation-App': X_CORRELATION_APP, // Link detection of request origin
		},
		withCredentials: AKAMAI_BOT_FLAG, // akamai bot detection
		data: {
			data: {
				sso_token: ssoTokenId,
				redirect_uri: redirectUrl,
				client_id: SITECORE_AUTHORIZATION.CLIENT_ID,
				decision: SITECORE_AUTHORIZATION.DECISION,
				response_type: SITECORE_AUTHORIZATION.RESPONSE_TYPE,
				scope: SITECORE_AUTHORIZATION.SCOPE,
				state: SITECORE_AUTHORIZATION.STATE,
			},
		},
	});
}

/**
 * Call Sitecore API for Member Logout
 */
export function memberLogout() {
	const url = `${getCurrentUrlHostname}${config.sitecoreApi.apiPathMemberLogout}`;

	return axios({
		url,
		method: 'POST',
		data: {},
		withCredentials: !isDevelopmentEnv,
	});
}

/**
 * Call Process Auth Code plus Browser Cookies
 * @param {data} response from the previous API
 * @param {domainName} host name from the current/active tab
 */
export function memberProcessAuthCode(data: any, domainName: string) {
	const baseUrl = `${data?.redirect_uri}?code=${data?.code}&iss=${data?.issuer}&state=${data?.state}&client_id=${data?.client_id}&redirect_uri=${domainName}`;

	return axios({
		url: baseUrl,
		method: 'POST',
		withCredentials: !isDevelopmentEnv,
	});
}

/**
 * Call Sitecore API for Member Refresh Token
 * @param {rtoken} old refresh token
 */
export function memberRefreshToken(rtoken: string) {
	const url = `${getCurrentUrlHostname}${config.sitecoreApi.apiPathMemberRefreshToken}`;

	return axios({
		url,
		method: 'POST',
		data: {
			refresh_token: rtoken,
		},
		withCredentials: !isDevelopmentEnv,
	});
}
