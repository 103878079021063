import LOGO_DARK from './logo.svg';

// ------------------------------------
// Core brand colours
// ! Local DRY use only; don't export

const ORANGE = {
	BASE: '#EA4403',
	DARK: '#C66600',
	LIGHT: '#FF9F39',
	LIGHTER: '#FFB364',
	LIGHTEST: '#FFF0E0',
};

const BLUE = {
	BASE: '#260046',
	DARK: '#0E1375',
	LIGHT: '#2C32AA',
	LIGHTER: '#7D6690',
	LIGHTEST: '#E3E4F2',
};

const YELLOW = {
	BASE: '#FFC600',
	DARK: '#C69900',
	LIGHT: '#FFD339',
	LIGHTER: '#FFDC64',
	LIGHTEST: '#F4EDD3',
};

const RED = {
	BASE: '#BA0C2F',
	DARK: '#98001E',
	LIGHT: '#DB2A4D',
	LIGHTER: '#EA5572',
	LIGHTEST: '#F7E2E6',
};

const GREEN = {
	BASE: '#0C7135',
	DARK: '#005723',
	MEDIUM_DARK: '#007345',
	LIGHT: '#1C8948',
	LIGHTER: '#399D61',
	LIGHTEST: '#E2EEE7',
};

const DARK_GREY = {
	BASE: '#53565A',
	DARK: '#2E2E2E',
	LIGHT: '#585858',
	MEDIUM_LIGHT: '#828282',
	LIGHTER: '#AEB2B6',
};

const PALE_GREY = {
	BASE: '#D9D9D6',
	DARK: '#B5B5B2',
	LIGHT: '#E9E9E6',
	LIGHTER: '#F9F9F6',
};

const LIGHT_BLUE = {
	BASE: '#EAE6ED',
	DARK: '#D4DEE8',
	LIGHT: '#EEF2F6',
	LIGHTER: '#F5F7FA',
	LIGHTEST: '#F4F2F5',
};

const PURPLE = {
	BASE: '#913B84',
};

// ------------------------------------
// Central colour definitions
// ! Local DRY use only; don't export

const BRAND_WHITE = '#FFFFFF';
const DARK_TEXT = DARK_GREY.DARK;
const LIGHT_TEXT = BRAND_WHITE;

// ------------------------------------
// Feedback colour
// ! Local DRY use only; don't export
const WARNING = {
	BASE: '#DB7100',
	LIGHT: '#FBF1E5',
};
const ERROR = {
	BASE: '#B00116',
	LIGHT: '#F7E6E8',
};
const INFO = {
	BASE: '#0488C5',
	LIGHT: '#DAEDF7',
};

// ------------------------------------
// Shared CTA styles
// ! Local DRY use only; don't export

const CTA_DISABLED = {
	color: DARK_GREY.LIGHTER,
	text: BRAND_WHITE,
	border: null,
};

const CTA_PRIMARY = {
	base: {
		color: BLUE.BASE,
		text: BRAND_WHITE,
		border: null,
	},
	loading: {
		color: BLUE.BASE,
		text: BRAND_WHITE,
		border: null,
	},

	hover: {
		color: BLUE.LIGHTER,
	},

	active: {
		color: BLUE.LIGHTER,
	},

	disabled: CTA_DISABLED,
};

// Theme Schema
// TODO set theme schema as a type in separate .types file

// ------------------------------------
// Core brand (global) colours

const ORANGE_BASE = {
	id: 'orange',
	name: 'Orange',
	color: ORANGE.BASE,
	isDark: false,
	text: DARK_TEXT,
};

const BLUE_BASE = {
	id: 'blue',
	name: 'Blue',
	color: BLUE.BASE,
	isDark: true,
	text: LIGHT_TEXT,
};

const YELLOW_BASE = {
	id: 'yellow',
	name: 'Yellow',
	color: YELLOW.BASE,
	isDark: false,
	text: DARK_TEXT,
};

const PURPLE_BASE = {
	id: 'purple',
	name: 'Purple',
	color: PURPLE.BASE,
	isDark: false,
	text: LIGHT_TEXT,
};

// ------------------------------------
// Compositions

const COMPOSITIONS = [
	// ------------------------------------
	// Orange

	ORANGE_BASE,

	{
		id: 'orange-dark',
		name: 'Orange - Dark',
		color: ORANGE.DARK,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'orange-light',
		name: 'Orange - Light',
		color: ORANGE.LIGHT,
		isDark: false,
		text: DARK_TEXT,
	},

	{
		id: 'orange-lighter',
		name: 'Orange - Lighter',
		color: ORANGE.LIGHTER,
		isDark: false,
		text: DARK_TEXT,
	},

	{
		id: 'orange-lightest',
		name: 'Orange - Lightest',
		color: ORANGE.LIGHTEST,
		isDark: false,
		text: DARK_TEXT,
	},

	// ------------------------------------
	// Blue

	BLUE_BASE,

	{
		id: 'blue-dark',
		name: 'Blue - Dark',
		color: BLUE.DARK,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'blue-light',
		name: 'Blue - Light',
		color: BLUE.LIGHT,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'blue-lighter',
		name: 'Blue - Lighter',
		color: BLUE.LIGHTER,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'blue-lightest',
		name: 'Blue - Lightest',
		color: BLUE.LIGHTEST,
		isDark: false,
		text: DARK_TEXT,
	},

	// ------------------------------------
	// Yellow

	YELLOW_BASE,

	{
		id: 'yellow-dark',
		name: 'Yellow - Dark',
		color: YELLOW.DARK,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'yellow-light',
		name: 'Yellow - Light',
		color: YELLOW.LIGHT,
		isDark: false,
		text: DARK_TEXT,
	},

	{
		id: 'yellow-lighter',
		name: 'Yellow - Lighter',
		color: YELLOW.LIGHTER,
		isDark: false,
		text: DARK_TEXT,
	},

	{
		id: 'yellow-lightest',
		name: 'Yellow - Lightest',
		color: YELLOW.LIGHTEST,
		isDark: false,
		text: DARK_TEXT,
	},

	// ------------------------------------
	// Red

	{
		id: 'red',
		name: 'Red',
		color: RED.BASE,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'red-dark',
		name: 'Red - Dark',
		color: RED.DARK,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'red-light',
		name: 'Red - Light',
		color: RED.LIGHT,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'red-lighter',
		name: 'Red - Lighter',
		color: RED.LIGHTER,
		isDark: false,
		text: DARK_TEXT,
	},

	{
		id: 'red-lightest',
		name: 'Red - Lightest',
		color: RED.LIGHTEST,
		isDark: false,
		text: DARK_TEXT,
	},

	// ------------------------------------
	// Green

	{
		id: 'green',
		name: 'Green',
		color: GREEN.BASE,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'green-dark',
		name: 'Green - Dark',
		color: GREEN.DARK,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'green-medium-dark',
		name: 'Green - Medium Dark',
		color: GREEN.MEDIUM_DARK,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'green-light',
		name: 'Green - Light',
		color: GREEN.LIGHT,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'green-lighter',
		name: 'Green - Lighter',
		color: GREEN.LIGHTER,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'green-lightest',
		name: 'Green - Lightest',
		color: GREEN.LIGHTEST,
		isDark: false,
		text: DARK_TEXT,
	},

	// ------------------------------------
	// Dark Grey

	{
		id: 'darkGrey',
		name: 'Dark Grey',
		color: DARK_GREY.BASE,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'darkGrey-dark',
		name: 'Dark Grey - Dark',
		color: DARK_GREY.DARK,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'darkGrey-light',
		name: 'Dark Grey - Light',
		color: DARK_GREY.LIGHT,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'darkGrey-medium-light',
		name: 'Dark Grey - Medium Light',
		color: DARK_GREY.MEDIUM_LIGHT,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'darkGrey-lighter',
		name: 'Dark Grey - Lighter',
		color: DARK_GREY.LIGHTER,
		isDark: true,
		text: LIGHT_TEXT,
	},

	// ------------------------------------
	// Pale Grey

	{
		id: 'paleGrey',
		name: 'Pale Grey',
		color: PALE_GREY.BASE,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'paleGrey-dark',
		name: 'Pale Grey - Dark',
		color: PALE_GREY.DARK,
		isDark: false,
		text: DARK_TEXT,
	},

	{
		id: 'paleGrey-light',
		name: 'Pale Grey - Light',
		color: PALE_GREY.LIGHT,
		isDark: true,
		text: LIGHT_TEXT,
	},

	{
		id: 'paleGrey-lighter',
		name: 'Pale Grey - Lighter',
		color: PALE_GREY.LIGHTER,
		isDark: true,
		text: LIGHT_TEXT,
	},

	// ------------------------------------
	// Light Blue

	{
		id: 'lightBlue',
		name: 'Light Blue',
		color: LIGHT_BLUE.BASE,
		isDark: false,
		text: DARK_TEXT,
	},

	{
		id: 'lightBlue-dark',
		name: 'Light Blue - Dark',
		color: LIGHT_BLUE.DARK,
		isDark: false,
		text: DARK_TEXT,
	},

	{
		id: 'lightBlue-light',
		name: 'Light Blue - Light',
		color: LIGHT_BLUE.LIGHT,
		isDark: false,
		text: DARK_TEXT,
	},

	{
		id: 'lightBlue-lighter',
		name: 'Light Blue - Lighter',
		color: LIGHT_BLUE.LIGHTER,
		isDark: false,
		text: DARK_TEXT,
	},

	{
		id: 'lightBlue-lightest',
		name: 'Light Blue - Lightest',
		color: LIGHT_BLUE.LIGHTEST,
		isDark: false,
		text: DARK_TEXT,
	},

	// ------------------------------------
	// Yellow

	PURPLE_BASE,
];

// ------------------------------------
// Types

export type TypographyColorType =
	| 'default'
	| 'subdued'
	| 'active'
	| 'error'
	| 'success';

export default {
	// This makes the theme object identifiable amongst other objects
	// that live in the JSS Layout Service API `siteSettings` array
	id: 'theme-schema',

	collectionName: 'Australian Super',

	global: {
		onboarding: {
			backgroundColor: '#D93E02',
			welcomeBanner: {
				color: YELLOW.LIGHTEST,
			},
		},
		logos: {
			dark: LOGO_DARK,
			// light: null,
		},

		page: {
			color: '#F5F5F5',
			isDark: false,

			breadcrumbs: {
				color: LIGHT_BLUE.LIGHTEST,
				isDark: false,
			},

			authenticatedHeader: {
				color: BRAND_WHITE,
				primary: {
					mobile: {
						fontColor: DARK_GREY.LIGHT,
						color: LIGHT_BLUE.BASE,
					},
					desktop: {
						fontColor: DARK_GREY.DARK,
					},
				},
				subItems: {
					color: LIGHT_BLUE.BASE,
					fontColor: DARK_GREY.DARK,
					desktop: {
						fontColor: DARK_GREY.BASE,
					},
				},
				activeBar: {
					color: ORANGE.BASE,
				},
				hamburgerButton: {
					color: DARK_GREY.LIGHT,
				},
				caret: {
					color: DARK_GREY.LIGHT,
				},
				notifications: {
					pill: {
						color: RED.BASE,
					},
				},
			},

			unauthenticatedFooter: {
				color: LIGHT_BLUE.LIGHTER,
				isDark: false,
			},

			tabs: {
				arrowButton: {
					color: LIGHT_BLUE.LIGHT,
				},
			},
		},

		module: {
			color: BRAND_WHITE,
			isDark: false,
		},

		notifications: {
			info: {
				color: INFO.BASE,
				background: INFO.LIGHT,
			},
			error: {
				color: ERROR.BASE,
				background: ERROR.LIGHT,
			},
			success: GREEN.BASE,
			warning: {
				color: WARNING.BASE,
				background: WARNING.LIGHT,
			},
			loading: {
				color: BLUE.BASE,
				background: LIGHT_BLUE.BASE,
			},
			close: DARK_GREY.LIGHT,
			color: BLUE.BASE,
		},

		typography: {
			colors: {
				default: DARK_GREY.DARK,
				subdued: DARK_GREY.LIGHT,
				active: BLUE.BASE,
				error: RED.BASE,
				success: GREEN.BASE,

				genericDark: DARK_TEXT,
				genericLight: LIGHT_TEXT,
			},

			hyperlinks: {
				color: BLUE.BASE,
				hover: BLUE.LIGHTER,
				isDark: true,
			},

			highlight: {
				color: BLUE.BASE,
				text: BRAND_WHITE,
			},
		},

		colors: {
			primary: ORANGE_BASE,
			secondary: BLUE_BASE,
			tertiary: YELLOW_BASE,

			disabled: COMPOSITIONS.find(cmp => cmp.color === DARK_GREY.LIGHTER),
		},

		ctas: {
			primary: {
				isDark: true,
				...CTA_PRIMARY,

				inverted: {
					base: {
						color: BRAND_WHITE,
						text: BLUE.BASE,
						border: null,
					},
					hover: {
						color: PALE_GREY.LIGHTER,
					},
					active: {
						color: PALE_GREY.LIGHT,
					},
					disabled: CTA_DISABLED,
				},
			},

			secondary: {
				isDark: false,

				base: {
					color: BRAND_WHITE,
					text: BLUE.BASE,
					border: BLUE.BASE,
				},

				loading: {
					color: BRAND_WHITE,
					text: BLUE.BASE,
					border: BLUE.BASE,
				},

				hover: {
					color: BRAND_WHITE,
					text: BLUE.LIGHTER,
					border: BLUE.LIGHTER,
				},

				active: {
					color: BRAND_WHITE,
				},

				disabled: { ...CTA_DISABLED, border: PALE_GREY.LIGHT },
				inverted: CTA_PRIMARY,
			},

			plain: {
				isDark: false,

				base: {
					color: null,
					text: BLUE.BASE,
					border: null,
				},

				loading: {
					color: null,
					text: BLUE.BASE,
					border: null,
				},

				hover: {
					text: BLUE.LIGHTER,
				},

				active: {
					text: BLUE.BASE,
				},

				disabled: {
					text: DARK_GREY.LIGHTER,
				},

				// TODO ?
				inverted: CTA_PRIMARY,
			},

			warning: {
				isDark: true,

				base: {
					color: RED.BASE,
					text: BRAND_WHITE,
					border: null,
				},

				loading: {
					color: RED.BASE,
					text: BRAND_WHITE,
					border: null,
				},

				hover: {
					color: RED.LIGHT,
				},

				active: {
					color: PALE_GREY.LIGHT,
				},

				disabled: CTA_DISABLED,
				inverted: CTA_PRIMARY,
			},
		},

		borders: {
			hr: LIGHT_BLUE.BASE,
			hrDark: DARK_GREY.DARK,
			module: '#DEE0E4',
			faded: DARK_GREY.LIGHTER,
			arrowButton: DARK_GREY.MEDIUM_LIGHT,
			mauSeparator: '#EBEBEB',
		},

		form: {
			select: {
				active: ORANGE.BASE,
				hover: LIGHT_BLUE.BASE,
				option: {
					base: DARK_GREY.BASE,
					hover: DARK_GREY.DARK,
				},
			},
			radio: {
				active: BLUE.BASE,
				hover: LIGHT_BLUE.BASE,
			},
			progressBar: {
				pastStep: { color: GREEN.BASE },
				futureStep: { color: LIGHT_BLUE.BASE },
				activeStep: { color: BLUE.BASE, text: BRAND_WHITE },
			},
		},

		icon: {
			orangeContainer: ORANGE.LIGHTEST,
			green: GREEN.LIGHT,
		},
	},

	variables: {
		baseTransition: '200ms',
	},

	themes: [
		{
			id: 'theme-light',
			name: 'Light',

			compositions: COMPOSITIONS,
		},
	],
};
