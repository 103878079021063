import config from 'jss-boilerplate/temp/config.js';

export const ACCOUNT_BASE = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathAccount}`;
export const CONTRIBUTIONS_BASE = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathContributions}`;
export const OUTBOUND_COMMS_BASE = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathOutboundCommunication}`;
export const AUDIT_BASE = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathSystemAudit}`;
export const FORMS_BASE = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathForm}`;
export const IDENTITY_BASE = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathIdentity}`;
export const PAYMENT_BASE = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathPayment}`;
export const REFERENCE_BASE = `${config.australianSuperApi.apiDomain}${config.australianSuperApi.apiPathReferenceData}`;
export const MEMBER_RESTRICTION_BASE = `${config.australianSuperApi.apiDomain}${config.australianSuperApi.apiPathSystemMemberRestriction}`;
export const WITHDRAWAL_BASE = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathWithdrawals}`;
