import styled, {
	css,
	FlattenSimpleInterpolation as CSS,
} from 'styled-components';

import * as mx from 'core/styles/mixins';
import { Icon } from 'core/components/Icon/Icon.style';
import { CtaType, CtaStateType } from 'pods/theme';
import { FONT_WEIGHT, FONT_NAMES } from 'core/styles/fonts';

// ------------------------------------
// Variables

export const CTA_CLASS_WRAPPER = 'cta-wrapper';
export const CTA_CLASS = 'cta';
export const JSS_CTA_CLASS = '-JssCtaLink';

export const CTA_PADDING = '8px 20px';
const CTA_BORDER_WIDTH = 1;

const CTA_SIZE = {
	extraSmall: '12px',
	small: '14px',
	medium: '16px',
	large: '18px',
};

// ------------------------------------

const getCtaStyles = (cta: CtaType, isInverted: boolean): CSS => {
	const base = isInverted ? cta.inverted.base : cta.base;
	const hover = { ...base, ...cta.hover };
	const active = { ...base, ...cta.active };
	const alert = { ...base, ...cta.alert };
	const disabled = { ...base, ...cta.disabled };
	const loading = { ...base, ...cta.loading };

	const getState = (state: CtaStateType): CSS => css`
		background-color: ${!state.color ? 'unset' : state.color};
		border: none;

		/* Set text color, ensuring to override any nested semantic anchor global styles. */
		&,
		a {
			color: ${state.text};
		}

		&:not(.-disabled) {
			${!!state.border &&
				css`
					/* If the cta has a border, offset the vertical padding
					so the height of the cta is equal to other CTAs without borders. */
					padding: ${CTA_PADDING.split(' ')
						.map((px: string, idx) =>
							idx === 0 ? `${parseInt(px) - CTA_BORDER_WIDTH}px` : px,
						)
						.join(' ')};
					border: ${CTA_BORDER_WIDTH}px solid ${state.border};
				`}
		}
	`;

	return (css as any)`
		${getState(base)}

		&:not(.-disabled) {
			&:hover,
			&.-hovered,
			&:focus {
				${getState(hover)}
				text-decoration: none;
			}

			&:focus {
				${mx.mimicFocus}
			}

			&:active {
				${getState(active)}
			}
		}

		${alert &&
			css`
				&.-alert {
					${getState(alert)}
				}
			`}

		&.-disabled {
			${getState(disabled)}
			cursor: not-allowed;
		}

		&.-loading {
			${getState(loading)}
			cursor: not-allowed;
		}
	`;
};

// CTAs are driven by the `.cta` class name and modifiers
// due to CMS requirements wherein authors need to be able
// to get visual parity in Sitecore RTE when making hyperlinks
// "look like CTAs" by adding a className to them
//
// ! Don't use the .cta class names in your components!
// ! -- The Cta component just uses these under the hood,
// ! -- and has corresponding component props for all these options
export const classNameCtas = css`
	.${CTA_CLASS},
		.${CTA_CLASS}-primary,
		.${CTA_CLASS}-secondary,
		.${CTA_CLASS}-plain
		.${CTA_CLASS}-warning {
		${mx.buttonReset};
		font-size: 16px;
		line-height: 1.5; /* TODO: Adjust to 1.2 when Cta size is small */
		display: inline-flex;
		align-items: center;
		outline: none;
		padding: ${CTA_PADDING};
		text-align: center;
		border-width: 2px;
		border-style: solid;
		border-radius: 24px;
		margin: 10px 0;
		position: relative;
		cursor: pointer;
		text-decoration: none;
		user-select: none;
		font-family: ${FONT_NAMES.OPEN_SANS};
		font-weight: ${FONT_WEIGHT.SEMI_BOLD};

		/* Emulates 'press down' */
		&:not(.-plain):not(.-disabled):active {
			top: 2px;
		}
	}

	.${CTA_CLASS} {
		${({ theme }) => {
			const ctas = theme.global.ctas;

			return css`
				/* --------------------------------
				Primary (by default) */

				&-primary {
					${getCtaStyles(ctas.primary, false)}

					&.-inverted {
						${getCtaStyles(ctas.primary, true)}
					}
				}

				/* -------------------------------- */

				&-secondary {
					${getCtaStyles(ctas.secondary, false)}

					&.-inverted {
						${getCtaStyles(ctas.secondary, true)}
					}
				}

				&-plain {
					${getCtaStyles(ctas.plain, false)}

					&.-inverted {
						${getCtaStyles(ctas.plain, true)}
					}

					/* Link alike cta that has a underline */
					&.-linkAlike {
						padding: 0;
						text-decoration: underline;
						text-decoration-color: inherit;
						font-weight: ${FONT_WEIGHT.REGULAR};
					}

					&.-withoutPadding {
						padding: 0;

						&.-isJssWithIcon {
							line-height: normal;
						}
					}
				}

				&-warning {
					${getCtaStyles(ctas.warning, false)}

					&.-inverted {
						${getCtaStyles(ctas.warning, true)}
					}
				}
			`;
		}}
	}
`;

// -----------------------------------

// Wraps the component purely so we can
// target it from other Styled Components
export const Cta = styled.div`
	display: inline-block;

	> .${CTA_CLASS} {
		/* Width 100% so this div controls the CTA tag (a, button, span) width */
		width: 100%;
	}
`;

export const CtaJssWithIcon = styled.i`
	display: flex;
	margin-left: 5px;
`;

export const Content = styled.span<{
	iconAlign: 'left' | 'right';
	size: 'extraSmall' | 'small' | 'medium' | 'large';
}>`
	position: relative;
	display: flex;
	align-items: center;

	${({ size }) => css`
		font-size: ${CTA_SIZE[size] ?? '16px'};
	`}

	${Icon} {
		right: auto;
		margin-left: 8px;
		align-self: center;
	}

	${({ iconAlign }) =>
		iconAlign === 'left' &&
		css`
			flex-direction: row-reverse;

			${Icon} {
				margin: 0 8px 0 0;
			}
		`};

	/* Override the style set from the scTextWrapper for align icon inside the anchor component */
	&&& > a {
		display: inherit;
		flex-direction: inherit;
	}
`;

export const SitecoreOverrides = styled.div<{
	isDisabled?: boolean;
	isLoading?: boolean;
	isWithoutPadding?: boolean;
	isJssWithIcon?: boolean;
}>`
	> .${CTA_CLASS} {
		/* Move padding to Sitecore's anchor tag so the click-to-edit
		area is as large as the whole CTA and not just its text. !important
		so as to override with certainty and not fight with derivative/calculated
		padding like on CTAs with borders. */

		/* Note: padding should be preserved for the loading state when there is no sitecore anchor tag */
		${({ isLoading }) =>
			!isLoading &&
			css`
				padding: 0 !important;
			`}
	}

	/* Sitecore's Experience Editor renders JSS links a little differently;
	links are just rendered as regular anchors, which we can target by the class name
	added in the <Cta/> component - it's preferable to have padding on the anchor itself
	and not on the 'CTA_CLASS' tag so that the actual clickable anchor is the ENTIRE CTA.
	Empty links (unauthored) omit the anchor tag and instead render a span with 'scTextWrapper'
	class, which we can hook into for visual parity with CTA content parity. */
	.${CTA_CLASS} a,
	.scTextWrapper {
		text-decoration: none;
		display: block;
		padding: ${({ isWithoutPadding }) => (isWithoutPadding ? 0 : CTA_PADDING)};

		&:focus {
			${mx.mimicFocus}
		}

		${({ isDisabled }) =>
			isDisabled &&
			css`
				pointer-events: none;
			`}
	}
`;
