export const ERROR_CODES = {
	TIMEOUT: 'ER02',
	GENERIC: 'ER10',
	INVALID_TFN: 'ER18',
	INVALID_TFN_API: 'ER18a',
	AMOUNT_EXCEEDS_BALANCE: 'ER21',
	FUND_NOT_SELECTED: 'ER22',
	ESA_NOT_SELECTED: 'ER55',
	NO_SMSF_SELECTED: 'ER56',
	//Income Payment
	AMOUNT_LOW: 'ER48',
	AMOUNT_HIGH: 'ER49',
	INVALID_BSB: 'ER20',
	REQUIRED_CHECK_RADIO: 'ER29',
	INVALID_VALUES: 'ER40',
	ACCOUNT_MAX_9_NUMBERS: 'ER41',
	TAL_SSO_NOT_AVAILABLE: 'ER72',
	CANNOT_SUBMIT: 'ER89',
	DELETE_ALL_BENEFICIARIES: 'ER86',
	//Direct Debit
	INVALID_ACCOUNT_NAME: 'ER42',
	INVALID_ZERO_CONTRIBUTION: 'ER95',
	INVALID_ACCOUNT_HOLDER: 'ER29',
	ACCOUNT_NAME_VALIDATION: 'ER135',
};

export const OTP_ERRORS = {
	NO_MEMBER: 'ER01',
	IDENTITY_LOCKED: 'ER02',
	INCORRECT_OTP: 'ER13',
	LOCKED_ACCOUNT: 'ER47',
};

export const REGISTRATION_EXP_ERRORS = {
	NOT_FOUND: 'ER02',
	GENERIC: 'ER10',
};

export const USERNAME_ERRORS = {
	USERNAME_TAKEN: 'ERU001',
	OTHERS: 'ERU002',
	INVALID_USERNAME: 'ER100',
};

// TODO: NO_PREVIOUSLY_USED wil be consume by Edit Username and Pwd cmp
export const PASSWORD_ERRORS = {
	NO_DISALLOWED_FIELDS: 'ERP001',
	NO_BLACKLISTED: 'ERP002',
	NO_EMAIL_FORMAT: 'ERP003',
	MIN_LENGTH: 'ERP004',
	MAX_LENGTH: 'ERP005',
	NO_REPEATING: 'ERP006',
	NO_PERSONAL_INFO: 'ERP007',
	NO_CONSECUTIVE: 'ERP009',
	NO_PREVIOUSLY_USED: 'ERP010',
	NO_BANNED_WORDS: 'ERP008',
	DEFAULT: 'ERP008',
};

export const USERNAME_ERRORS_CONTENT = {
	USERNAME_TAKEN: 'ER63',
};

export const PASSWORD_ERRORS_CONTENT = {
	NO_PERSONAL_INFO: 'ER59',
	NO_EMAIL_FORMAT: 'ER60',
	NO_USERNAME: 'ER61',
	NO_BANNED_WORDS: 'ER62',
	OTHER_ERROR: 'ER98',
};

export const LOGIN_ERRORS = {
	INCORRECT: 'ER03',
	UNREGISTERED: 'ER107',
	LOCKED_CHILD_ACCOUNT: 'ER139',
	CLOSED_ACCOUNT: 'ER140',
};

export const REQUIRED_FIELD = 'ER01';

export const CONTACT_DETAILS_ERRORS = {
	INVALID_EMAIL: 'ER06',
	INVALID_HOME_MOBILE: 'ER07',
	INVALID_ADDRESS: 'ER08',
	INVALID_STREET_ADDRESS: 'ER12',
	INVALID_SUBURB: 'ER12',
	INVALID_POSTCODE_AU: 'ER09',
	INVALID_POSTCODE_INTEL: 'ER66',
	CANNOT_SUBMIT_FORM: 'ER89a',
	INVALID_MOBILE_NUMBER: 'ER142',
};

export const ADDRESS_AUTOCOMPLETE_ERRORS = {
	INVALID_ADDRESS: 'ER08',
	INVALID_POSTCODE_AU: 'ER09',
	INVALID_STREET_ADDRESS: 'ER12',
	INVALID_SUBURB: 'ER15',
	INVALID_POSTCODE_INTEL: 'ER66',
};

export const INVESTMENT_ERRORS = {
	DEFAULT: 'ER10',
	DECIMAL_NOT_ALLOWED: 'ER131',
	INVALID_ALLOCATION_ITEM: 'ER71',
	INVALID_ALLOCATION_TOTAL: 'ER50',
	INVALID_ORDER_SEQUENCE: 'ER68',
	CANCEL_SWITCH_NOT_ALLOWED: 'ER52',
	MEMBER_DIRECT_REGISTERED_BUTINELIGIBLE: 'ER45',
	PENDING_SWITCH_NOT_AVAILABLE: 'ER51',
};

// TODO Add error for locked account
export const RECOVER_USERNAME_ERRORS = {
	DEFAULT: 'ER10',
	MULTIPLE_ACCOUNTS_ERROR: 'ER54',
	NAME_REQUIRED: 'ER16',
	NO_DETAILS_FOUND: 'ER70',
	READY_TO_REGISTER: 'ER77',
	// LOCKED_ACCOUNT_ERROR: '__TODO__'
};

export const PASSWORD_USERNAME_RESET_ERRORS = {
	INCOMPLETE_REGISTRATION: 'ER107',
};

export const WITHDRAWAL_ERRORS = {
	FIELD_REQUIRED: 'ER01',
	CHECKBOX_REQUIRED: 'ER29',
	MISSING_CENTRELINK: 'ER35',
	NO_DEBT_ENTERED: 'ER31',
	WITHDRAWAL_AMOUNT_MISMATCH: 'ER36',
	MINIMUM_BALANCE: 'ER37',
	MINIMUM_BALANCE_6K: 'ER39',
	MINIMUM_PENSION_AMOUNT: 'ER73',
	ABN_NOT_FOUND: 'ER57',
	AMT_MORE_THAN_10K: 'ER30',
	INSUFFICIENT_FUNDS_AVAILABLE: 'ER33',
	INVALID_CRN: 'ER28',
	AMOUNT_MORE_THAN_AVAILABLE_BALANCE: 'ER34',
	MISSING_VALID_FUND: 'ER38',
	WHOLE_NUMBER: 'ER99',
	AMT_LESS_THAN_1K: 'ER73',
	INVALID_SMSF_ABN: 'ER103',
};

export const CO_BROWSE_ERRORS = {
	SERVICE_CODE_INVALID: 'ER65',
};

export const COMM_PREF_ERRORS = {
	UNABLE_TO_UPDATE_PRIMARY_COMMS_PREF: 'ER24',
	NO_CHANGES_DETECTED: 'ER27',
};

export const GREENID_ERRORS = {
	API_ERROR: 'ER80',
	LOCKED_PDF_DOWNLOAD_ERROR: 'ER85',
};

export const DIRECT_DEBIT_ERRORS = {
	MAX_CONTRIBUTION_AMOUNT: 'ER132',
};
