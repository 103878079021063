export const SUCCESS_CODES = {
	UPDATE_EMAIL_ADDRESS: 'SN04',
	UPDATE_CONTACT_NUMBER: 'SN05',
	UPDATE_ADDRESS: 'SN06',
	UPDATE_USERNAME: 'SN13',
	UPDATE_PASSWORD: 'SN14',
	UPDATE_PAYMENT: 'SN07',
	UPDATE_TFN: 'SN08',
	UPDATE_COMM_PREF: 'SN09',
	UPDATE_MOBILE_NUMBER: 'SN18',
};
