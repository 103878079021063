import React from 'react';

import * as Typo from 'core/styles/Typography';
import { Icon, ICONS } from 'core/components/Icon';
import * as AC from 'core/styles/AnimationComponents';

import * as S from './LoginSpinner.style';

interface IProps {
	dataTargetId?: string;
}

export const LoginSpinner = ({ dataTargetId = 'login-spinner' }: IProps) => (
	<S.Wrapper>
		<S.Container>
			<AC.Rotate>
				<Icon name={ICONS.SPINNER} size={100} />
			</AC.Rotate>
			<Typo.DisplaySmall
				data-target-id={`${dataTargetId}--spinner-label`}
				margin="48px 0 0 0"
			>
				{/* TODO: Use sitecore content */}
				{`Securely logging you in...`}
			</Typo.DisplaySmall>
		</S.Container>
	</S.Wrapper>
);
