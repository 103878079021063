import {
	getSessionCookieItem,
	setSessionCookieItem,
	removeSessionCookieItem,
} from './sessionCookie';

const centrelinkStatus = 'centrelinkStatus';

/**
 * Get centrelink status
 * @return {centrelinkStatus cookie value} string status of centerlink, possible values: 0/null/empty string - initial state | 1 - generating | 2 - generation complete | 3 - error generation | 4 - downloading | 5 - download complete | 6 - error download
 */
export const getCentrelinkStatus = (): string => {
	return getSessionCookieItem(centrelinkStatus);
};

/**
 * Set centrelink status
 * @param {tokenValue} string status of centerlink, possible values: 0/null/empty string - initial state | 1 - generating | 2 - generation complete | 3 - error generation | 4 - downloading | 5 - download complete | 6 - error download
 */
export const setCentrelinkStatus = (tokenValue: string) => {
	setSessionCookieItem(centrelinkStatus, tokenValue);
};

/**
 * Remove centrelink status from cookie browser storage
 */
export const removeCentrelinkStatus = () => {
	removeSessionCookieItem(centrelinkStatus);
};
